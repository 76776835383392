import React from 'react';
import Head from 'next/head';
import NoSSR from 'react-no-ssr';
import ReactDOMServer from 'react-dom/server';
import { Sticky, StickyContainer } from 'react-sticky';
import * as queryString from 'query-string';
import { StickyHeader } from '../src/components/ui/document/StickyHeader';
import { IBMComSearchkitManager } from '../src/core/IBMComSearchkitManager';
import { SearchkitProvider} from 'searchkit';
import { IBMComLoading } from '../src/components/ui/idl/IBMComLoading';
import { Config } from '../src/core/utils/Config';
import { Utils } from '../src/core/utils/Utils';
import { ABTestUtils } from '../src/core/utils/ABTestUtils';
import { get, set, includes } from 'lodash';
import { IBMComDotcomShell } from '../src/components/ui/idl/IBMComDotcomShell';
import { TranslationAPI } from '@carbon/ibmdotcom-services';
import { SearchTipsLink } from '../src/components/ui/modal/SearchTipsLink';
import { SearchTipsModal } from '../src/components/ui/modal/SearchTipsModal';
import classnames from 'classnames';
import { LocaleLinks } from '../src/components/ui/document/LocaleLinks';
import { ABTest } from '../src/components/ui/ABTest';
import { GlobalEvents } from '../src/components/ui/GlobalEvents';
import { IBMComSearchBox } from '../src/components/search/search-box/IBMComSearchBox';
import { DomManipulation } from '../src/core/utils/DOMManipulation';
import { IBMComSearchBoxHidden } from '../src/components/search/search-box/IBMComSearchBoxHidden';
import {
  MainLayout,
  IBMHomePage
} from '../src/components/layouts';

const initialState = {
  showLoader: false,
  isSearchTipsOpen: false,
  isFiltersModalOpen: false,
};

class SearchApp extends React.Component {
  static REGEX_QUERYPARAM = /^[^\?]*\?(.*)$/;

  static PATH_URL_SEARCH_SCOPED = '/search/scoped';
  constructor(props) {
    super(props);

    this.searchkit = new IBMComSearchkitManager(
      Config.URL_SEARCH_BACKEND_HOST,
      {
        useHistory: !Config.isServer(),
        searchOnLoad: false,
        defaultSize: Config.DEFAULT_SIZE,
      },
      {
        results: props[0].results,
        state: props[0].state,
        referrer: props[3],
        acceptLanguages: props[4]
      }
    );

    // SearchApp.setFeatureFlagsFromPath(props[3]);

    this.searchkit.state.footerTranslations = props[2];

    this.searchkit.translateFunction = (key) => {
      const cclc = `${get(props, '[0].state.cc', 'us')}-${get(
          props,
          '[0].state.lang',
          'en'
        )}`,
        translationsInCCLC = get(props, `[1].${cclc}`);

      return get(translationsInCCLC, key, key);
    };

    const q = get(this.searchkit, 'state.q');

    this.state = { ...initialState, q };

    this.toggleSearchTips = this.toggleSearchTips.bind(this);
    this.toggleFiltersModal = this.toggleFiltersModal.bind(this);
    this.hiddenInputRef = React.createRef(null);
    this.initializeLogging();
  }

  initializeLogging() {
    const asPath = get(this.props, '3', '');

    // Set the APP_ID as a global
    if (this.isScopedSearch(asPath)) {
      const urlObject = queryString.parseUrl(asPath),
        appID = get(urlObject, 'query.appid', 'noappid');

      Config.ID_APP_CURRENT = `${Config.ID_APPS.SEARCH_SCOPED}-${appID}`;
    } else {
      Config.ID_APP_CURRENT = Config.ID_APPS.SEARCH;
    }
  }

  componentDidCatch(error, info) {
    Config.clientSideLogInfo({
      action: 'Error was thrown',
      info: get(info, 'componentStack'),
      error: error.message,
    });
  }

  isScopedSearch(asPath) {
    const isScoped = (asPath || '').startsWith(
      SearchApp.PATH_URL_SEARCH_SCOPED
    );

    return isScoped;
  }

  async componentDidMount () {
    try {
      Config.getWindow().addEventListener('searchResults', () => Utils.setTrackingUsedURLReferrer(true));
      await this.searchkit.reloadSearch();
    } catch (err) {
      Config.clientSideLogInfo({
        action: 'Error was thrown',
        error: err.message,
      });
    }
  }

  static getMastheadHeight = () =>
    Config.isServer()
      ? SearchApp.MASTHEAD_HEIGHT_DEFAULT
      : get(
          document.getElementsByClassName('bx--masthead'),
          '[0].offsetHeight',
          SearchApp.MASTHEAD_HEIGHT_DEFAULT
        );

  static async getInitialProps(props) {
    if (!Config.isServer()) {
      return Promise.resolve({});
    }

    const { asPath, ctx, req } = props,
      locale = Utils.getLocaleFromRequest(req),
      searchPath = locale.queryString,
      searchkit = new IBMComSearchkitManager(
        Config.URL_SEARCH_BACKEND_HOST,
        {
          useHistory: false,
          searchOnLoad: false,
          location: searchPath,
        },
        {}
      ),
      referrer = get(ctx, 'req.referrer', get(ctx, 'req.referer'));

    ReactDOMServer.renderToString(SearchApp.searchCode(searchkit, null));
    searchkit.emitter.clear();

    Config.clientSideLogInfo({
      action: 'page initial load',
      type: 'user behavior tracking',
      referrer,
    });

    return Promise.all([
      searchkit.searchFromUrlQuery(searchPath), // results and state
      Utils.getTranslation(locale.cclc), // translations for static labels
      Config.isServer() ? null : TranslationAPI.getTranslation(locale), // Carbon for ibm.com translations
      asPath, // referrer
      get(req, 'headers["accept-language"]', ''), // accept-language header for SSR
    ]);
  }

  static MASTHEAD_HEIGHT_DEFAULT = 48; // known height for carbon masthead without nav

  toggleSearchTips() {
    const {isSearchTipsOpen} = this.state;
    this.setState((prev) => ({ ...prev, isSearchTipsOpen: !isSearchTipsOpen }));
  }

  toggleFiltersModal() {
    const {isFiltersModalOpen} = this.state;
    this.setState((prev) => ({ ...prev, isFiltersModalOpen: !isFiltersModalOpen }));
  }

  static searchCode(searchkit, searchApp, asPath) {
    const lc = get(searchkit, 'state.lang'),
      cc = get(searchkit, 'state.cc'),
      lcCC = `${lc}-${cc}`,
      scope =
        Config.getSearchEmbeddedDataGlobal('adopter.scope') ||
        get(searchkit, 'state.scope'),
      appid =
        Config.getSearchEmbeddedDataGlobal('adopter.appId') ||
        get(searchkit, 'state.appid'),
      q = get(searchkit, 'state.q'),
      isExport = Config.IS_STATIC_EXPORT,
      isProductsPage =
        Config.getSearchEmbeddedDataGlobal('isProductsPage') || false,
      isEmbeddedProductsPage = isExport && isProductsPage,
      isEmbeddedProductsPageWithLeadspace =
        isEmbeddedProductsPage &&
        Config.getSearchEmbeddedDataGlobal('layout.noLeadspace') !== true,
      isELS =
        isExport && (Config.getSearchEmbeddedDataGlobal('isELS') || false),
      showDefaultNoHitsPage =
        Config.getSearchEmbeddedDataGlobal('defaultNoHitsPage'),
      sidebarLocationLeft =
        Config.getSearchEmbeddedDataGlobal('layout.leftSidebarLocation') ||
        (isEmbeddedProductsPage ? true : false),
      showPageSizeOnTop =
        isEmbeddedProductsPage &&
        Config.getSearchEmbeddedDataGlobal('layout.showPageSizeOnTop') !==
          false,
      isProductsTab = Utils.isProductsTab(get(searchkit, 'state')),
      showProductsFilters =
        get(searchkit, 'state.products') !== 'false' &&
        get(searchkit, 'state.tabType[0]') ===
          Config.TYPES_SEARCH_ID_TAB.products &&
        get(searchkit, 'results.hits.total', 0) !== 0 &&
        Config.isLocaleSupportedByMarketplace(lcCC),
      showLearningFilters =
        get(searchkit, 'state.tabType[0]') ===
          Config.TYPES_SEARCH_ID_TAB.learning &&
        get(searchkit, 'results.hits.facet_number', 0) !== 0 &&
        Config.isLocaleSupportedByMarketplace(lcCC),
      showSupportFilters =
        get(searchkit, 'state.support') !== 'false' &&
        get(searchkit, 'state.tabType[0]') ===
          Config.TYPES_SEARCH_ID_TAB.support &&
        Config.isLocaleSupportedByMarketplace(lcCC),
      showDownloadsFilters =
        get(searchkit, 'state.downloads') !== 'false' &&
        get(searchkit, 'state.tabType[0]') ===
          Config.TYPES_SEARCH_ID_TAB.downloads &&
        Config.isLocaleSupportedByMarketplace(lcCC),
      showDeveloperFilters = appid === 'dw' && scope === 'dw',
      showAnalystFilters = appid === 'analyst' && scope === 'analyst',
      isSearchTipsOpen = get(searchApp, 'state.isSearchTipsOpen', false),
      onToggleSearchTips = get(searchApp, 'toggleSearchTips', () => {}),
      // onUpdateRef = get(searchApp, 'updateRef', () => {}),
      hiddenInputBox = get(searchApp, 'hiddenInputRef', null),
      searchInputPlaceHolderText = searchkit.translateFunction('Search'),
      isLoading = get(searchkit, 'loading', false),
      isInitialLoading = get(searchkit, 'isInitialLoading', false),
      noHits = get(searchkit, 'results.hits.total', 0) === 0,
      showHome = !q && !isProductsTab ? true : false,
      tabType = get(searchkit, 'state.tabType[0]', '') || 'ibmcom',
      facetTabs = get(searchkit, 'state.facetTabs', ''),
      shouldFakeFacetNoHitsShow = searchkit.shouldFakeFacetNoHitsShow(
        tabType,
        facetTabs
      ),
      showSimpleSearchBar = get(searchApp, 'showSimpleSearchBar', false),
      contentClassNames = classnames({
        'ibm--content': true,
        'ibm--content--is-initial-loading': isInitialLoading,
        'ibm--content--is-loading': isLoading,
        'ibm--content--no-hits': showDefaultNoHitsPage,
        'ibm--content--fake-facets': shouldFakeFacetNoHitsShow,
      }),
      embeddedContentClassNames = classnames({
        'ibm--embedded': isExport,
        'ibm--embedded-products': isEmbeddedProductsPage,
      }),
      sidebarClassNames = classnames({
        'ibm--content__sidebar': true,
        'bx--col-xlg-3': isEmbeddedProductsPage,
        'bx--col-lg-4': isEmbeddedProductsPage,
        'bx--col-xlg-5': !isEmbeddedProductsPage,
        'bx--col-lg-5': !isEmbeddedProductsPage,
        'bx--col-md-8': true,
        'bx--col-sm-4': true,
        'ibm--all-tab': tabType === 'ibmcom',
      }),
      resultsClassNames = classnames({
        'ibm--content__results': true,
        'bx--col-xlg-12': isEmbeddedProductsPage,
        'bx--col-lg-12': isEmbeddedProductsPage,
        'bx--col-xlg-11': !isEmbeddedProductsPage,
        'bx--col-lg-11': !isEmbeddedProductsPage,
        'bx--col-md-8': true,
        'bx--col-sm-4': true,
      }),
      filterByLabel = searchkit.translateFunction('Filter by'),
      isFiltersModalOpen = get(searchApp, 'state.isFiltersModalOpen', false),
      onToggleFiltersModal = get(searchApp, 'toggleFiltersModal', () => {}),
      testGroup = ABTestUtils.getInstance().getABTestGroup(),
      isScopedSearch = includes(asPath, 'scoped'),
      embeddedSearchBox = isEmbeddedProductsPage &&
        !isEmbeddedProductsPageWithLeadspace && (
          <IBMComSearchBox autofocus={false} />
        ),
      embeddedTitle = Config.getSearchEmbeddedDataGlobal('leadspaceTitle', 'Find a product'),
      embeddedProductsPageLeadspaceSearchBox = isEmbeddedProductsPage &&
        isEmbeddedProductsPageWithLeadspace && (
          <div className='ibm--searchbar'>
            <div className='bx--grid'>
              <div className='bx--row ibm--searchbar__heading'>
                <div className='bx--col-xlg-4 bx--col-lg-4 bx--col-md-8 bx--col-sm-4'></div>
                <div className='bx--col-xlg-12 bx--col-lg-12 bx--col-md-8 bx--col-sm-4'>
                  <h1 className=''>{ embeddedTitle }</h1>
                </div>
              </div>
              <div className='bx--row bx--grid--condensed ibm--searchbar__input'>
                <div className='bx--col-xlg-4 bx--col-lg-4 bx--col-md-8 bx--col-sm-4'></div>
                <div className='bx--col-xlg-12 bx--col-lg-12 bx--col-md-8 bx--col-sm-4'>
                  <IBMComSearchBox autofocus={false} />
                </div>
              </div>
            </div>
          </div>
        ),
      embeddedLeadspaceSearchBox = isExport && (
        <>
          {isEmbeddedProductsPage ? (
            embeddedProductsPageLeadspaceSearchBox
          ) : (
            <IBMComSearchBox autofocus={false} />
          )}
        </>
      ),
      allProps = {
        resultsClassNames,
        filterByLabel,
        showDownloadsFilters,
        showLearningFilters,
        showProductsFilters,
        showSupportFilters,
        showDeveloperFilters,
        onToggleFiltersModal,
        searchkit,
        isScopedSearch,
        sidebarClassNames,
        isFiltersModalOpen,
        isEmbeddedProductsPage,
        showPageSizeOnTop,
        isELS,
        embeddedSearchBox,
        isExport,
        sidebarLocationLeft
      },
      mainContent = (
        <div className={contentClassNames}>
          <IBMComLoading active={isLoading} />
          <MainLayout {...allProps} />
        </div>
      );

    if (!Config.isServer() && !Config.IS_STATIC_EXPORT) {
      const bodyElem = document.querySelector('body');

      set(
        bodyElem,
        'className',
        Utils.getBodyClassName(isProductsPage, isProductsTab, q)
      );
    }

    if (searchApp) {
      searchkit.addSearchingStatusCallback('toggleLoader', (isSearching) => {
        if (isSearching) {
          DomManipulation.addClass(
            get(Config.getWindow(), 'document.body'),
            'ibm--loading-overlay--open'
          );
        } else {
          DomManipulation.removeClass(
            get(Config.getWindow(), 'document.body'),
            'ibm--loading-overlay--open'
          );
        }
        searchApp.setState((prev) => ({ ...prev, showLoader: isSearching }));

        LocaleLinks.updateLinks(
          get(searchkit, 'state.q', ''),
          get(searchkit, 'state.tabType[0]')
        );
      });
    }

    return (
      <SearchkitProvider searchkit={searchkit}>
        <div className={embeddedContentClassNames}>
          <ABTest
            testGroup={testGroup}
            nameOfTest={ABTestUtils.TEST_AB_NAME}
            description={ABTestUtils.TEST_AB_DESCRIPTION}
          />
          <GlobalEvents />
          <IBMComDotcomShell
            {...IBMComDotcomShell.CONFIG_INITIALIZE_SHELL_DOTCOM}
            searchTerm={get(searchkit, 'state.q', '')}
            placeHolderText={searchInputPlaceHolderText}
            has-search={showHome ? false : true}
            activateSearch={showHome ? false : true}
            hiddenInputBox={hiddenInputBox}
          >
            {embeddedLeadspaceSearchBox}
            {!showSimpleSearchBar && (
              <div className='bx--grid'>
                <div className='bx--row ibm--tips__container'>
                  <div className='bx--col'>
                    <SearchTipsLink onToggleSearchTips={onToggleSearchTips} />
                  </div>
                </div>
              </div>
            )}
            {isExport ? (
              mainContent
            ) : (
              <>
                <div style={{ display: 'none' }}>
                  <IBMComSearchBoxHidden ref={hiddenInputBox} />
                </div>
                <StickyContainer>
                  <Sticky
                    disableCompensation
                    topOffset={-SearchApp.getMastheadHeight()}
                  >
                    {({ isSticky, style, calculatedHeight }) => (
                      <StickyHeader
                        style={style}
                        isSticky={isSticky}
                        calculatedHeight={calculatedHeight}
                        showSimpleSearchBar={showSimpleSearchBar}
                        locale={`${searchkit.state.cc}-${searchkit.state.lang}`}
                        topOffset={SearchApp.getMastheadHeight()}
                        onToggleSearchTips={onToggleSearchTips}
                      />
                    )}
                  </Sticky>
                  {showHome ? (
                    <IBMHomePage
                      hiddenInputBox={hiddenInputBox}
                      highlightFirstSuggestion={
                        IBMComDotcomShell.CONFIG_INITIALIZE_SHELL_DOTCOM
                          .highlightFirstSuggestion
                      }
                      placeHolderText={searchInputPlaceHolderText}
                      searchkit={searchkit}
                      showHome={showHome}
                    />
                  ) : (
                    mainContent
                  )}
                </StickyContainer>
              </>
            )}
          </IBMComDotcomShell>

          {!isExport && (
            <SearchTipsModal
              isSearchTipsOpen={isSearchTipsOpen}
              onToggleSearchTips={onToggleSearchTips}
            />
          )}
        </div>
      </SearchkitProvider>
    );
  }

  getTitle(asPath) {
    const isProductsPage = Utils.isProductsPage(asPath),
      metadata = isProductsPage
        ? Config.METADATA_PRODUCTS
        : Config.METADATA_SEARCH,
      metaTitle = get(metadata, 'title');

    return this.searchkit.translateFunction(metaTitle);
  }

  render() {
    const language = this.props.lang,
      isRTL = Config.isRTLLanguage(language),
      asPath = get(this.props, '3');

    return (
      <main role='main'>
        {
          !Config.IS_STATIC_EXPORT && (
            <Head>
              <title>
                {this.getTitle(asPath)}
              </title>
            </Head>
          )
        }
        <div id='pcon'>
          <div id='ibm-content'>
            <div
              id='ibm-content-body'
              className='ibm-padding-top-0'
              dir={isRTL ? 'rtl' : undefined}
            >
              <div id='ibm-content-main'>
                {SearchApp.searchCode(this.searchkit, this, asPath)}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default SearchApp;
