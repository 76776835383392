import React, { useEffect, useState } from 'react';
import root from 'window-or-global';
import { get, set } from 'lodash';

import { IBMComMastheadSearch } from './IBMComMastheadSearch';

const IBMComMastheadSearchWrapper = ({
  hiddenInputBox,
  placeHolderText,
  highlightFirstSuggestion,
  additionalState,
  searchTerm,
  showHome,
}) => {
  const [searchTermValue, setSearchTermValue] = useState(searchTerm);

  useEffect(() => {
    const handlePopState = root.addEventListener('popstate', (event) => {
      const currentStateValue = get(hiddenInputBox, 'current.accessor.state.value'),
        resultsStateValue = get(
          hiddenInputBox,
          'current.accessor.resultsState.value'
        );

      if (currentStateValue !== resultsStateValue) {
        // onUpdateRef(currentStateValue)
        hiddenInputBox.current.setNewValue(currentStateValue);
        setSearchTermValue(currentStateValue);
      }
    });

    return () => {
      root.removeEventListener('popstate', () => handlePopState);
    };
  }, []);


  const onSearchTermChange = ({ val }) => {
    // onUpdateRef(val)
    hiddenInputBox.current.setNewValue(val);
    setSearchTermValue(val);
    setTimeout(() => {
      const ddsSearchBox = document.querySelector('dds-search-with-typeahead');

      set(ddsSearchBox, '_searchInputNode.value', val);
    }, 100);
  };

  return (
    <IBMComMastheadSearch
      searchOpenOnload={true}
      placeHolderText={placeHolderText}
      onSearchTermChange={onSearchTermChange}
      searchTerm={searchTermValue}
      highlightFirstSuggestion={highlightFirstSuggestion}
      additionalState={additionalState}
      showHome={showHome}
    />
  );
};

export default IBMComMastheadSearchWrapper;
