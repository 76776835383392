import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import root from 'window-or-global';
import { settings as ddsSettings } from '@carbon/ibmdotcom-utilities';
import { settings } from 'carbon-components';
import { HeaderGlobalAction } from 'carbon-components-react';
import { Close, Search } from '../../icons';
import { Config } from '../../../core/utils/Config';

const { stablePrefix } = ddsSettings;
const { prefix } = settings;

/**
 * Renders the input bar with the search icon
 *
 * @param {object} props Incoming props
 * @param {object} props.componentInputProps contains the input props
 * @param {boolean} props.isActive flag to determine if the search is active
 * @param {Function} props.searchIconClick executes when the search icon is clicked
 * @returns {*} The rendered component
 */
const IBMMastheadSearchInput = ({
  componentInputProps,
  dispatch,
  isActive,
  searchIconClick,
  initialSearchTerm,
  showHome
}) => {
  const searchRef = useRef();

  /**
   * Clear search and clear input when called
   */
  const resetSearch = useCallback(() => {
    if(!showHome) dispatch({ type: 'setSearchClosed' });
    dispatch({
      type: 'setVal',
      payload: { val: '' },
    });
  }, [dispatch]);

  /**
   * closeBtnAction resets and sets focus after search is closed
   */
  function closeBtnAction() {
    resetSearch();
    const searchIconRef = root.document.querySelectorAll(
      `[data-autoid="${stablePrefix}--header__search--search"]`
    );
    searchIconRef && searchIconRef[0].focus();
  }

  useEffect(() => {
    if (isActive) {
      searchRef.current && searchRef.current.focus();
    } else resetSearch();
  }, [isActive, resetSearch]);

  return (
    <>
      {
        Config.isServer() ?
        <input
          {...componentInputProps}
          data-autoid={`${stablePrefix}--header__search--input`}
          className={`${prefix}--header__search--input`}
          ref={searchRef}
          name="q"
          tabIndex={isActive ? null : '-1'}
          value={initialSearchTerm}
        /> :
        <input
          {...componentInputProps}
          data-autoid={`${stablePrefix}--header__search--input`}
          className={`${prefix}--header__search--input`}
          ref={searchRef}
          name="q"
          tabIndex={isActive ? null : '-1'}
        />
      }
      <HeaderGlobalAction
        onClick={searchIconClick}
        aria-label={componentInputProps.placeholder}
        className={`${prefix}--header__search--search`}
        data-autoid={`${stablePrefix}--header__search--search`}>
        <Search size={20}/>
      </HeaderGlobalAction>
      <HeaderGlobalAction
        onClick={closeBtnAction}
        aria-label="Close"
        className={`${prefix}--header__search--close`}
        data-autoid={`${stablePrefix}--header__search--close`}>
        <Close size={20} />
      </HeaderGlobalAction>
    </>
  );
};

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{placeHolderText: shim, renderValue: shim}}
 * @param {boolean} isActive Search input active state
 */
IBMMastheadSearchInput.propTypes = {
  componentInputProps: PropTypes.object,
  dispatch: PropTypes.func,
  isActive: PropTypes.bool,
  searchIconClick: PropTypes.func,
};

/**
 * @property defaultProps
 * @type {{placeHolderText: string, renderValue: number}}
 */
IBMMastheadSearchInput.defaultProps = {
  componentInputProps: {},
  dispatch: () => {},
  searchIconClick: () => {},
};

export {IBMMastheadSearchInput};
